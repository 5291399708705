import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1 className="text-3xl font-bold underline">Hello world!</h1>
    <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
      className="lg:w-full md:w-1/2 sm:w-1/2 w-full"
    />
    <p>
      <Link className="btn-primary" to="/styles">
        Styles Page
      </Link>{" "}
      <br />
    </p>
  </Layout>
)

export default IndexPage
